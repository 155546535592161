
import React, { useState, useEffect, useRef } from 'react'
import { RichText } from 'prismic-reactjs'
import { Link } from 'gatsby'
import "../styles/pages/newHeader.scss"

const HeaderLandL = ({
	currentPage = null,
	navigation,
	visibleOnlyOnTopOfPage = false,
	additionalClasses = ""
}) => {

	const topNav = navigation.data.top_navigation;

	/**
	*	Hide nav on scroll down / Show it back on scroll up
	*/
	const [isNavVisible, setIsNavVisible] = useState(true)
	const [isHeaderMinimised, setIsHeaderMinimised] = useState(false)
	const [headerLogoHeight, setHeaderLogoHeight] = useState(72)
	const lastScrollTop = useRef(0)
	useEffect(() => {
		// return
		// skip header show hide operations on scroll
		// if (localStorage.getItem('is-touch') === 'true') return;
		// if (window.innerWidth < 415 && window.innerHeight > 500) return;

		const handleScroll = () => {
			const newScrollTop = document.documentElement.scrollTop
			// if (newScrollTop > lastScrollTop.current && isNavVisible === true) { // down
			// 	setIsNavVisible(false)
			// } else if (newScrollTop <= lastScrollTop.current && isNavVisible === false) { // up
			// 	if (visibleOnlyOnTopOfPage === false || newScrollTop <= 0) {
			// 		setIsNavVisible(true)
			// 	}
			// }
			const diff = lastScrollTop.current - (newScrollTop <= 0 ? 0 : newScrollTop)
			lastScrollTop.current = newScrollTop <= 0 ? 0 : newScrollTop; // For Mobile or negative scrolling
			// const triggerPoint = 100
			// setIsHeaderMinimised(lastScrollTop.current > triggerPoint)
			if(headerLogoHeight >= 35 && headerLogoHeight <= 72){
					const threshold = window.innerWidth * 0.2513;

					if(diff < 0 && headerLogoHeight > 35){
						const reducedHeight = headerLogoHeight - 2
						setHeaderLogoHeight(reducedHeight < 35 ? 35 : reducedHeight)
						setIsHeaderMinimised(false)
					}else if(diff > 0 && headerLogoHeight < 72 && threshold > lastScrollTop.current){
						const increasedHeight = headerLogoHeight + 2
						setHeaderLogoHeight(increasedHeight > 72 ? 72 :increasedHeight )
						setIsHeaderMinimised(false)
						if(lastScrollTop.current === 0 ){
							setHeaderLogoHeight(72)
						}
					}else if(headerLogoHeight === 35){
						setIsHeaderMinimised(true)
					}
			}
		}

		window.addEventListener('scroll', handleScroll)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	})

	/**
	*	Burger
	*/
	const [isBurgerOpen, setIsBurgerOpen] = useState(false);

	const handleOnKeyDown = e => {
		if (e.key === 'Enter') {
			onBurgerIconClick();
		}
	}

	const onBurgerIconClick = () => {
		setIsBurgerOpen(!isBurgerOpen);
	}

  return (
    <header className={`Header ${additionalClasses}`} style={{ backgroundColor: "#1D1D1D"}}>
			<Link
				to="/"
				className={`Header__Logo ${isNavVisible ? 'visible' : ''}`}
				data-cursor-hide
			>
			{
			!isHeaderMinimised &&	(
				<svg  width="50" height={headerLogoHeight} viewBox="0 0 50 72" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M49.9663 0H32.9323C32.0639 0.881255 31.4627 1.59304 30.9283 2.30482L30.4273 2.98271C29.7593 3.93175 29.1247 4.94859 28.1895 6.43994C25.15 11.253 22.5114 17.5912 24.9496 23.2516C27.9557 30.1999 37.0071 37.4872 31.9303 45.588C31.4627 46.3676 30.8281 47.2488 30.2937 48.0623H49.9997V0H49.9663Z" fill="white"/>
				<path d="M27.2879 43.1815C29.3921 39.9954 30.4609 37.0466 29.0581 33.2843C26.3861 26.2004 19.0715 20.6078 20.4409 12.3715C21.2091 7.76182 23.3133 3.69449 26.1189 0H1.002V48.0623H23.5471C25.0501 46.232 25.8517 45.3507 27.2879 43.1815ZM0 58.5696L1.33601 57.3833C1.77021 57.9934 2.57181 58.4679 3.44021 58.4679C4.07482 58.4679 4.74282 58.2645 4.74282 57.6883C4.74282 57.1799 4.30862 56.9765 3.80761 56.841L2.37141 56.4681C1.2358 56.1631 0.400802 55.4852 0.400802 54.1294C0.400802 52.5364 1.73681 51.4178 3.57381 51.4178C5.04342 51.4178 5.91182 51.7907 6.64663 52.7058L5.27722 53.8921C4.87642 53.3498 4.20842 53.0787 3.54041 53.0787C2.93921 53.0787 2.40481 53.282 2.40481 53.8582C2.40481 54.3328 2.90581 54.5022 3.54041 54.6717L4.84302 55.0107C6.04542 55.3157 6.84703 56.0275 6.84703 57.3494C6.84703 59.1797 5.21042 60.1287 3.50701 60.1287C2.07081 60.0948 0.801603 59.6881 0 58.5696ZM7.81563 51.5195H9.91984V54.7734H12.6252V51.5195H14.6961V59.9931H12.5918V56.6037H9.88644V59.9592H7.78223V51.5195H7.81563ZM16.0321 51.5195H18.1363V59.9931H16.0321V51.5195ZM19.4389 51.5195H21.5431V58.2306H25.5177V59.9592H19.4389V51.5195ZM26.6533 51.5195H30.1269C31.9973 51.5195 33.5337 52.5364 33.5337 54.5022V56.8748C33.5337 58.9763 31.8971 59.9592 30.1269 59.9592H26.6533V51.5195ZM30.0267 58.2306C30.7949 58.2306 31.4629 57.7561 31.4629 56.8748V54.57C31.4629 53.7566 30.7949 53.2481 29.9933 53.2481H28.7909V58.2306H30.0267V58.2306ZM36.7735 51.4856H39.0114L41.9506 59.9592H39.813L39.312 58.3662H36.3393L35.8383 59.9592H33.8677C33.8677 59.9592 36.7735 51.4856 36.7735 51.4856ZM38.7776 56.7393L37.8089 53.6888H37.7755L36.8069 56.7393H38.7776ZM42.819 51.5195H44.8564L48.0294 56.2986H48.0628V51.5195H50V59.9931H48.0628L44.7896 55.0107H44.7562V59.9931H42.819V51.5195V51.5195Z" fill="white"/>
				<path d="M0.334473 68.2295V65.6535C0.334473 63.5859 2.00448 62.5352 3.84149 62.5352C5.44469 62.5352 7.2483 63.3148 7.2483 65.2807V65.5857H5.27769V65.4162C5.27769 64.535 4.54289 64.2299 3.87489 64.2299C3.14008 64.2299 2.40528 64.6367 2.40528 65.5857V68.1956C2.40528 69.0768 3.03988 69.5853 3.87489 69.5853C4.64309 69.5853 5.24429 69.2463 5.24429 68.3312V68.1278H3.57429V66.5687H7.2483V68.5006C7.2483 70.4326 5.47809 71.28 3.87489 71.28C2.03788 71.28 0.334473 70.3648 0.334473 68.2295ZM8.4841 62.7047H12.1247C14.0285 62.7047 15.0973 63.8232 15.0973 65.3824C15.0973 66.3992 14.5963 67.416 13.5275 67.8905L15.4313 71.1783H13.1601L11.5235 68.1956H10.5883V71.1783H8.4841V62.7047ZM11.8575 66.6026C12.6257 66.6026 13.0599 66.1619 13.0599 65.484C13.0599 64.7723 12.5923 64.3655 11.8575 64.3655H10.5549V66.6026H11.8575V66.6026ZM16.1327 68.1956V65.7552C16.1327 63.6198 17.8695 62.5352 19.6397 62.5352C21.5102 62.5352 23.2136 63.552 23.2136 65.7213V68.06C23.2136 70.2632 21.41 71.28 19.6731 71.28C17.8361 71.28 16.1327 70.3648 16.1327 68.1956ZM21.1428 68.1617V65.6535C21.1428 64.7723 20.4414 64.2638 19.6731 64.2638C18.9383 64.2638 18.2369 64.6706 18.2369 65.6535V68.1278C18.2369 69.043 18.8715 69.5175 19.7065 69.5175C20.4414 69.5514 21.1428 69.1107 21.1428 68.1617ZM24.416 68.1617V62.7047H26.4868V68.0261C26.4868 68.9752 26.9878 69.5853 27.923 69.5853C28.7914 69.5853 29.2924 69.043 29.2924 68.0261V62.7047H31.3298V68.0939C31.3298 70.1954 29.7934 71.3139 27.8228 71.3139C25.7186 71.28 24.416 70.2632 24.416 68.1617ZM32.599 62.7047H36.4066C38.2436 62.7047 39.3124 63.8571 39.3124 65.4162C39.3124 66.8059 38.4774 68.2295 36.44 68.2295H34.7366V71.1444H32.6324V62.7047H32.599ZM36.0392 66.6026C36.774 66.6026 37.2416 66.1619 37.2416 65.484C37.2416 64.7723 36.774 64.3655 36.0058 64.3655H34.6698V66.6026H36.0392V66.6026Z" fill="white"/>
				</svg>
			)	
			}
			{isHeaderMinimised && (<svg width="36" height={headerLogoHeight} viewBox="0 0 36 35" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M34.9782 0H22.8105C22.1902 0.62868 21.7608 1.13646 21.379 1.64424L21.0211 2.12784C20.544 2.80488 20.0907 3.53028 19.4226 4.5942C17.2515 8.02776 15.3667 12.5494 17.1084 16.5875C19.2556 21.5444 25.7212 26.7431 22.0948 32.5221C21.7608 33.0783 21.3074 33.7069 20.9257 34.2873H35.0021V0H34.9782Z" fill="white"/>
				<path d="M18.7784 30.8053C20.2815 28.5324 21.045 26.4287 20.0429 23.7448C18.1343 18.6911 12.9093 14.7014 13.8875 8.8257C14.4362 5.53722 15.9393 2.63562 17.9434 0H0.00195312V34.2873H16.1063C17.1799 32.9815 17.7525 32.3528 18.7784 30.8053Z" fill="white"/>
				</svg>
				)
			}
			</Link>
      <nav
				className={`Header__Nav ${isNavVisible ? 'visible' : ''}`}
				data-cursor-hide
			>
        <ul className="Header__NavList">
          {topNav.map((navItem, index) => {
			const isGetInTouch =  RichText.asText(navItem.link_text.raw) === "Get In Touch";
						let isCurrent = false
						if (navItem.link.type === currentPage) {
							isCurrent = true
						}
            return (
              <li
				key={`link-${index}`}
				className={`Header__NavItem ${isCurrent ? 'current' : ''} ${isGetInTouch ? 'get-in-touch' : ''}`}
				>
				{isGetInTouch ? (
					<>
					<Link to="/get_in_touch">
						{RichText.asText(navItem.link_text.raw)}
					</Link>
					<div className="dropdown" style={{ backgroundColor: "#1D1D1D"}}>
						<Link to="/lunchandlearn">
						Continuing Education
						</Link>
					</div>
					</>
				) : (
					<Link to={navItem.link.url}>
					{RichText.asText(navItem.link_text.raw)}
					</Link>
				)}
              </li>
            );
          })}
        </ul>
      </nav>
			<div className={`Header__Burger ${isBurgerOpen ? 'Header__Burger--Open' : ''}`}>
				<div
					className="Header__BurgerIcon"
					onClick={onBurgerIconClick}
					onKeyDown={handleOnKeyDown}
					role="button"
					tabIndex={0}
				>
					<div className="Header__BurgerIconLine Header__BurgerIconLine--Top"></div>
					<div className="Header__BurgerIconLine Header__BurgerIconLine--Bottom"></div>
				</div>
				<div className="Header__BurgerContainer">
					<ul className="Header__BurgerList">
						<li className="Header__BurgerListItem">
							<Link to="/">
								Homepage
							</Link>
						</li>
						{topNav.map((navItem, index) => {
							let isCurrent = false
							if (navItem.link.type === currentPage) {
								isCurrent = true
							}
							return (
								<li
									key={`link-burger-${index}`}
									className={`Header__BurgerListItem ${isCurrent ? 'current' : ''}`}
								>
									<Link to={navItem.link.url}>
										{RichText.asText(navItem.link_text.raw)}
									</Link>
								</li>
							)
						})}
					</ul>
				</div>
			</div>
    </header>
  )
}

export const query = graphql`
  fragment HeaderQuery on PrismicNavigation {
    data {
      top_navigation {
        link {
          type
          uid
          url
        }
        link_text {
          raw
        }
      }
    }
  }
`

export default HeaderLandL