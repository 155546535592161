import React, { useState, useContext, useEffect } from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import "../styles/pages/terracottaCladdingAustralia.scss"
import HeaderLandL from '../components/HeaderL&L'
import { detectElementsOnScreen, killObservers } from '../helpers/detectElementsOnScreen'
import { ContextLoader } from '../context';
import SliderProjectsTCA from '../components/SliderProjectsTCA'
import aebImg from '../images/AEB-UQ.jpg'
import white76 from '../images/White_076.png'
import row_4 from '../images/28&7g.jpeg'
import row_5 from '../images/sunscreen_photo.jpeg'
import row_6 from '../images/NYT_1.jpg'
import slider_image_1 from '../images/21_Harris.png'
import slider_image_2 from '../images/3_Murray.png'
import slider_image_3 from '../images/55_Miller.png'
import slider_image_4 from '../images/King_St.jpg'
import slider_image_5 from '../images/bayside_police.jpg'
import QR_CODE from '../images/Australia_cropped.png'
import Footer from '../components/Footer'


const terracottaCladdingAustralia = ({ data }) => {

  const [isLoading] = useContext(ContextLoader);
	useEffect(() => {
		if (isLoading === true) return
		const [observers, elements] = detectElementsOnScreen()
		return () => {
			killObservers(observers, elements)
		}
	}, [isLoading])

  const prismicFooter = data.prismicFooter
  const pageTitle = "Shildan Australia | Terracotta Cladding";
	const metaDescription = "Shildan Australia. Terracotta Cladding";
	const metaKeywords = "Architectural, Shildan, Terracotta Cladding, Shildan Australia";



    const selectedProjects = [
  {
    "uid": "21 Harris St",
    "image": {
      "alt": "21 Harris St",
      "fluid": {
        "src": slider_image_1,
      }
    },
    "title": {
      "text": "21 Harris St, Prymont NSW 2009, Australia"
    },
    "location": {
      "text": "Architect: Bates Smart & ASPECT Studios | Owner: Milligan Group"
    },
    "characteristics": [
      {
        "label": {
          "text": ""
        },
        "content": {
          "text": ""
        }
      }
    ]
  },
  {
    "uid": "3 Murray Rose Ave",
    "image": {
      "alt": "3 Murray Rose Ave",
      "fluid": {
        "src": slider_image_2,
      }
    },
    "title": {
      "text": "3 Murray Rose Ave, Sydney Olympic Park NSW 2127, Australia"
    },
    "location": {
      "text": "Architect: Turner+Associates | Owner: The GPT Group | Contractor: Lendlease Building Pty Ltd"
    },
    "characteristics": [
      {
        "label": {
          "text": ""
        },
        "content": {
          "text": ""
        }
      }
    ]
  },
  {
    "uid": "55 Miller St",
    "image": {
      "alt": "55 Miller St",
      "fluid": {
        "src": slider_image_3,
      }
    },
    "title": {
      "text": "55 Miller St, Pyrmont NSW 2009, Australia"
    },
    "location": {
      "text": "Architect: Bates Smart | Owner: Winten Property Group | Contractor: Broad Constructions"
    },
    "characteristics": [
      {
        "label": {
          "text": ""
        },
        "content": {
          "text": ""
        }
      }
    ]
  },
  {
    "uid": "2 King Street",
    "image": {
      "alt": "2 King Street",
      "fluid": {
        "src": slider_image_4,
      }
    },
    "title": {
      "text": "2 King Street, Brisbane"
    },
    "location": {
      "text": "Architect: FJC Studio | Owner: Heitman and Abacus Property Group"
    },
    "characteristics": [
      {
        "label": {
          "text": ""
        },
        "content": {
          "text": ""
        }
      }
    ]
  },
  {
    "uid": "Bayside Police Station",
    "image": {
      "alt": "Bayside Police Station",
      "fluid": {
        "src": slider_image_5,
      }
    },
    "title": {
      "text": "Bayside Police Station 25/31 Abbott St, Sandringham VIC 3191, Australia"
    },
    "location": {
      "text": "Architect: FJMT - Francis-Jones Morehen Thorp"
    },
    "characteristics": [
      {
        "label": {
          "text": ""
        },
        "content": {
          "text": ""
        }
      }
    ]
  }
]



  const navItems = {
    data: {
      top_navigation: [
        { 
          link_text: { raw: [{ type: "paragraph", text: "Design & Innovation" }] },
          link: { type: "design", url: "/design_and_innovation" }
        },
        { 
          link_text: { raw: [{ type: "paragraph", text: "Our Products" }] },
          link: { type: "products", url: "/our_products" }
        },
        { 
          link_text: { raw: [{ type: "paragraph", text: "Featured Projects" }] },
          link: { type: "projects", url: "/projects" }
        },
        { 
          link_text: { raw: [{ type: "paragraph", text: "Get In Touch" }] },
          link: { type: "contact", url: "/get_in_touch" }
        },
        { 
          link_text: { raw: [{ type: "paragraph", text: "Blog" }] },
          link: { type: "blog", url: "/blog" }
        },
        { 
          link_text: { raw: [{ type: "paragraph", text: "Download Center" }] },
          link: { type: "downloads", url: "/download_center" }
        }
      ]
    }
  };

  return (
  <div className="tcaOuterContainer">
    <HeaderLandL currentPage="" navigation={navItems} />
    <div className="tcaContainer">
      <SEO title={pageTitle} description={metaDescription} keywords={metaKeywords} />
      <div className="tca-row-1-container">
          <h1 className="tca-row-1-h1">Terracotta Cladding: A Timeless Element in Modern Architecture</h1>
          <img src={aebImg} alt="Shildan Group" className="aebImg" />
          <h4 className="tca-row-1-h4">Advanced Engineering Building (AEB) at the University of Queensland in Australia | Architect: Richard Kirk and Hassell </h4>
      </div>
      <div className="tca-row-2-container">
          <div className="tca-row-2-1">
                <h1 className="tca-row-2-1-header">What is Cladding?</h1>
                <p className="tca-row-2-1-words">Cladding refers to the external layer or skin applied to a building, which serves multiple functions, including protection against weather elements, insulation, and aesthetic enhancement. It can be made from various materials such as wood, metal, brick, and, importantly, terracotta. Cladding systems play a crucial role in the building's overall performance and appearance, providing a barrier against moisture and temperature fluctuations.</p>
          </div>
          <div className="tca-row-2-2">
                <h1 className="tca-row-2-2-header">What is Terracotta Cladding?</h1>
                <p className="tca-row-2-2-words">Terracotta cladding involves using fired clay panels as a building envelope. These panels are crafted from natural materials, making them a sustainable choice for architects and builders. Terracotta cladding is renowned for its rich colors, textures, and versatility, allowing it to complement various architectural styles while providing durability and resistance to environmental conditions.</p>
          </div>
          <div className="tca-row-2-3">
                <h1 className="tca-row-2-3-header">Advantages of Terracotta Cladding:</h1>
                <p className="tca-row-2-3-words">We believe that beauty is sustainable. Our terracotta rainscreen systems offer a beautiful aesthetic that will last hundreds of years, impacting the landscape of the neighborhoods it inhabits.​</p>
          </div>
      </div>
      <div className="tca-row-3-container">
        <div className="tca-row-3-ul-container">
          <ul className="tca-row-3-ul-bullets">
            <li className="tca-row-3-li">Terracotta is a warm, durable, natural masonry material</li>
            <li className="tca-row-3-li">No CO2 is generated from terracotta raw material</li>
            <li className="tca-row-3-li">Very low CO2 generated during production</li>
            <li className="tca-row-3-li">EPD & HPD certifications</li>
            <li className="tca-row-3-li">Improved thermal performance</li>
            <li className="tca-row-3-li">Non-combustible & fire resistant</li>
            <li className="tca-row-3-li">Recycled material</li>
            <li className="tca-row-3-li">Lead free glazes</li>
            <li className="tca-row-3-li">Highly engineered systems</li>
            <li className="tca-row-3-li">Maintenance free</li>
            <li className="tca-row-3-li">Terracotta does not fade or change over time</li>
            <li className="tca-row-3-li">No grouts, caulks, or sealants</li>
            <li className="tca-row-3-li">Light weight</li>
            <li className="tca-row-3-li">Ease of replacement of one panel if necessary</li>
            <li className="tca-row-3-li">Ease of installation</li>
          </ul>
        </div>
        <div className="tca-row-3-img-container">
          <img src={white76} alt="Shildan Group" className="aebImg" />
        </div>
      </div>
      <div className="tca-row-4-container">
        <hr className="custom-line"></hr>
        <div className="tca-row-4-left">
          <h1 className="tca-row-4-left-header">Terracotta Cladding with Rainscreen</h1>
          <p className="tca-row-4-left-words">Our terracotta wall cladding rainscreen system is a high performing, back ventilated, pressure equalized, open jointed rainscreen system, designed using the Rainscreen Principle. The Rainscreen Principle is a theory governing the design of a building enclosure in such a way as to prevent water penetration due to rain. Our system allows air to circulate behind the panels to provide pressure equalization which prevents water from being drawn into the building.</p>
          <p className="tca-row-4-left-words">Our system utilizes a shiplap in the open joint which resists wind driven rain and does not allow the rain to penetrate the open cavity behind the cladding. Because the space behind the rainscreen is open, it allows any moisture or vapor to escape via the back-ventilation properties of the design, allowing any stack effect to properly vent out. The natural ventilation prevents any mold or mildew from growing inside the wall.</p>
          <p className="tca-row-4-left-words">Terracotta wall cladding can be designed as a rainscreen system, which incorporates a ventilated cavity between the cladding and the building's structural wall. This design allows moisture to escape while protecting the building from water intrusion.</p>
        </div>
        <div className="tca-row-4-img-container">
        <img src={row_4} alt="Shildan Group" className="row-4-pic" />
        </div>
        <div className="tca-row-4-lower-left">
          <h1 className="tca-row-4-lower-left-header">Key Benefits:</h1>
          <ul className="tca-row-4-ul-bullets">
            <li className="tca-row-4-li">Moisture Management: The ventilated cavity allows air to circulate, preventing moisture buildup and protecting the building envelope from water damage.</li>
            <li className="tca-row-4-li">Enhanced Thermal Performance: The rainscreen acts as a thermal barrier, reducing heat transfer and improving the building's energy efficiency.</li>
            <li className="tca-row-4-li">Improved Durability: The system shields the structural wall from environmental factors like rain, UV rays, and temperature fluctuations, extending the building’s lifespan.</li>
            <li className="tca-row-4-li">Aesthetic Flexibility: Terracotta panels can be customized in various colors, shapes, and finishes to achieve unique architectural designs.</li>
            <li className="tca-row-4-li">Ease of Maintenance: The modular system allows for easy replacement and maintenance of individual panels without affecting the entire facade.</li>
          </ul>
          </div>
          <div className="tca-row-4-lowest-left">
            <h1 className="tca-row-4-lower-left-header">Installation:</h1>
            <p className="tca-row-4-lowest-left-words">Involves a framework that supports the cladding, creating an air gap that enhances moisture control. Proper alignment and spacing are crucial to maintaining the effectiveness of the rainscreen.</p>
          </div>
          <hr className="custom-line-2"></hr>
      </div>
      <div className="tca-row-5-container">
        <div className="tca-row-5-left">
          <h1 className="tca-row-5-left-header">Terracotta Cladding with Suncreen</h1>
          <p className="tca-row-5-left-words">Terracotta sunscreens can lend subtle variation, add texture to a building, or create a work of art, while offering solar shading. Both energy efficient and aesthetically pleasing, terracotta sunscreens are composed of extruded terracotta material in a wide variety of profiles, colors, and finishes.</p>
          <p className="tca-row-5-left-words">Harnessing the dynamic capabilities and beauty of terracotta to create lightweight brise soleil façades for buildings, our terracotta sunscreens create an open, permeable experience that allows natural light in while reducing the effects of the sun’s rays, providing superior thermal comfort without blocking visual contact with the outside world. The result is as quantifiable as it is inspiring, actively shaping the experience and identity of buildings in powerful ways.</p>
          <p className="tca-row-5-left-words">Terracotta Sunscreens are often incorporated into Terracotta cladding projects to create a seamless transition from wall cladding to shading elements.</p>
        </div>
        <div className="img-container">
        <img src={row_5} alt="Shildan Group" className="row-4-pic" />
        </div>
        <div className="tca-row-5-lower-left">
          <h1 className="tca-row-5-lower-left-header">Key Benefits:</h1>
          <ul className="tca-row-4-ul-bullets">
            <li className="tca-row-4-li">Enhanced Solar Protection: The sunscreen elements, often in the form of terracotta louvers or fins, reduce solar gain and glare, improving energy efficiency and occupant comfort.</li>
            <li className="tca-row-4-li">Dynamic Facades: The combination of terracotta panels and sunscreens creates visually striking facades that play with light and shadow, adding depth and texture to the building envelope.</li>
            <li className="tca-row-4-li">Durability and Sustainability: Terracotta is a durable, natural material that requires minimal maintenance, making it a sustainable choice for cladding and sun shading.</li>
            <li className="tca-row-4-li">Customizable Design Options: Architects can choose from various terracotta shapes, colors, and finishes to design sunscreen systems that complement the building’s overall aesthetic.</li>
          </ul>
          </div>
          <div className="tca-row-5-lowest-left">
            <h1 className="tca-row-4-lower-left-header">Installation:</h1>
            <p className="tca-row-4-lowest-left-words">Sunscreens are typically mounted alongside or integrated into the terracotta cladding system. Their placement is carefully planned to maximize solar protection based on the building's orientation.</p>
          </div>
          <hr className="custom-line-3"></hr>
      </div>
      <div className="tca-row-6-container">
      <div className="tca-row-6-h1-container">
      <h1 className="tca-row-6-top-header">Terracotta Cladding & Ceramic Cladding: A Lasting Fusion of Tradition and Innovation</h1>
      </div>
      <div className="tca-row-6-left"> 
      <h1 className="tca-row-5-left-header">Architectural Terracotta Cladding</h1>
      <ul className="tca-row-6-ul-bullets">
            <li className="tca-row-4-li">Natural Aesthetics: Terracotta offers rich, warm, earthy tones that provide a timeless look to buildings. The color palette ranges from traditional reds and browns to custom glazes, allowing for diverse design options.</li>
            <li className="tca-row-4-li">Sustainability: As a natural material, terracotta is eco-friendly and fully recyclable.</li>
            <li className="tca-row-4-li">Durability: It is highly resistant to UV rays, weather changes, and pollutants, ensuring that the facade retains its color and texture over decades.</li>
            <li className="tca-row-4-li">Thermal Performance: Terracotta cladding panels are excellent for energy efficiency as they can improve the thermal insulation of buildings.</li>
            <li className="tca-row-4-li">Fire and Moisture Resistance: Built to withstand the elements, terracotta panels offer exceptional fire and moisture resistance—making them the perfect choice for high-rise buildings and humid climates where durability meets design.</li>
          </ul>
      </div>
      <div className="tca-row-6-lower-left"> 
      <h1 className="tca-row-5-left-header">Architectural Ceramic Cladding</h1>
      <ul className="tca-row-6-ul-bullets">
            <li className="tca-row-4-li">Modern Aesthetic: Ceramic cladding offers a sleek, polished look with a vast range of colors and finishes, from matte to high-gloss, providing architects with more creative flexibility.</li>
            <li className="tca-row-4-li">Lightweight & Strong: Ceramic panels are lighter in weight, which can reduce the structural load on buildings. Despite being lightweight, they are extremely strong and durable.</li>
            <li className="tca-row-4-li">Low Maintenance: The non-porous surface of ceramic panels makes them resistant to dirt, moisture, and chemicals, which results in minimal maintenance over time.</li>
            <li className="tca-row-4-li">Fire and Moisture Resistance: Ceramic panels are highly resistant to fire and moisture, making them ideal for high-rise buildings and structures in humid environments.</li>
          </ul>
      </div>
      <div className="img-container">
        <img src={row_6} alt="Shildan Group" className="row-4-pic" />
      </div>
      <hr className="custom-line-4"></hr>
      </div>
      <div className="tca-row-7-container">
      <div className="tca-row-7-h1-container">
      <h1 className="tca-row-7-top-header">Notable Terracotta Cladding Projects</h1>
      </div>
      <SliderProjectsTCA selectedProjects={selectedProjects} />
      <div className="tca-line-container">
      <hr className="custom-line-5"></hr>
      </div>
      </div>
      <div className="tca-row-8-container">
        <div className="tca-row-8-h1-container">
        <h1 className="tca-row-8-top-header">Shildan's Terracotta Cladding Capabilities</h1>
        </div>
        <div className="tca-row-8-left">
            <p className="tca-row-8-left-words">At Shildan, we design and manufacture high-performance terracotta cladding solutions that bring innovation and elegance to architectural designs. Our terracotta rainscreens, sunscreens, decking, pavers, and curtain walls not only enhance a building's aesthetic appeal but also improve its durability and energy efficiency.</p>
            <p className="tca-row-8-left-words">With a commitment to sustainability, durability, and innovative design, Shildan provides architectural terracotta solutions that stand the test of time. Elevate your next project with the beauty and performance of Shildan terracotta facade panels.</p>
        </div>
          <div className="tca-row-8-lower-left"> 
            <ul className="tca-row-8-ul-bullets">
                <li className="tca-row-4-li">Expert Partnerships: State-of-the-art production and collaboration with industry leaders for unmatched craftsmanship and innovation.</li>
                <li className="tca-row-4-li">High-Quality Materials: Raw material used for sustainable and durable terracotta solutions.</li>
                <li className="tca-row-4-li">Cutting-Edge Technology: Advanced production techniques for terracotta rainscreen and sunscreen systems.</li>
                <li className="tca-row-4-li">Technical Expertise: Comprehensive technical support, from design assistance to installation training.</li>
                <li className="tca-row-4-li">Unlimited Possiblities: Standard and custom details tailored to meet specific project needs.</li>
                <li className="tca-row-4-li">In-House Capabilities: Shop drawings, engineering, project management, and 3D modeling for precise execution.</li>
                <li className="tca-row-4-li">Installation Support: On-site training to ensure proper and efficient installation.</li>
            </ul>
          </div>
            <div className="tca-row-8-lowest-left">
                <p className="tca-row-8-lowest-left-words">Discover more at <a className="tca-link-style" href="https://www.shildan.com" target="_blank">www.shildan.com</a>. Elevate your professional development by scheduling a Lunch & Learn or Wine & Cheese session at <a className="tca-link-style" href="https://www.shildan.com/lunchandlearn" target="_blank">www.shildan.com/lunchandlearn</a>.</p>
            </div>
            <hr className="custom-line-6"></hr>
      </div>
      <div className="tca-row-9-container">
      <div className="tca-row-9-h1-container">
        <h1 className="tca-row-9-top-header">
        <a className="tca-row-9-link" href="https://maphub.net/Shildan_Group/shildans-guide-to-australian-projects" target="_blank">Click here to take a virtual tour of our Australian projects
        </a>
        </h1>
        </div>
        <div className="tca-row-9-column-2">
        <div className="tca-row-9-img-container">
        <img src={QR_CODE} alt="Shildan Group"/>
        </div>
        </div>
      </div>
      <div className="tca-footContainer">
      <Footer footer={prismicFooter.data} hasBorder={true} showImage={false} />
      </div>
    </div>
  </div>

  )
}

export const query = graphql`
query TCA {
  prismicProjects {
    data {
      banner_title {
        raw
      }
      description {
        raw
      }
      section_search_title {
        text
      }
      selected_projects {
        project {
          document {
            ... on PrismicSelectedProject {
              id
              uid
              data {
                title {
                  text
                }
                location {
                  text
                }
                image {
                  alt
                  fluid {
                    src
                    srcSet
                  }
                }
                characteristics {
                  label {
                    text
                  }
                  content {
                    text
                  }
                }
              }
            }
          }
        }
      }
      products_types_list {
        value
      }
      colors_list {
        value
      }
      cities_list {
        value
      }
      systems_list {
        value
      }
      projects_types_list {
        value
      }
    }
  }
  prismicNavigation {
    ...HeaderQuery
  }
  prismicFooter {
    ...FooterQuery
  }
}
`


export default terracottaCladdingAustralia
