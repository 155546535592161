import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import ImageSliderSwiperjs from './ImageSliderSwiperjs';
import Picture from './Picture';

const SliderProjectsTCA = ({ selectedProjects }) => {

	let newSliderData = []
	selectedProjects.forEach(item=>{
		let newSlider = {
			image: item.image.fluid.src, 
			caption: item.title.text,
			location: item.location.text,
			uid: item.uid        
		}
		newSliderData.push(newSlider)
	})

	const slidesLength = selectedProjects.length;
	let currentIndex = 0;

	// Init image
	useEffect(() => {
		document.querySelector('.SliderProjects__ImageItem:first-child').classList.add('current');
	}, [])

	const updateCurrentSlide = (dir) => {
		let canTransform = false;
		if (dir === 'prev') {
			if (currentIndex > 0) {
				currentIndex -= 1;
			}
			else {
				currentIndex = slidesLength - 1;
			}
			canTransform = true;
		}
		else if (dir === 'next') {
			if (currentIndex < slidesLength - 1) {
				currentIndex += 1;
			}
			else {
				currentIndex = 0;
			}
			canTransform = true;
		}

		if (!canTransform) return;

		// Update image
		document.querySelector('.SliderProjects__ImageItem.current').classList.remove('current');
		[...document.querySelectorAll('.SliderProjects__ImageItem')][currentIndex].classList.add('current');

		// Update title
		const titleRollerEl = document.querySelector('.SliderProjects__InfosTitleRoller');
		const titleElHeight = titleRollerEl.children[0].offsetHeight;
		titleRollerEl.style.transform = `translate3d(0, ${titleElHeight * -currentIndex}px, 0)`;

		// Update location
		const locationRollerEl = document.querySelector('.SliderProjects__InfosLocationRoller');
		const locationElHeight = locationRollerEl.children[0].offsetHeight;
		locationRollerEl.style.transform = `translate3d(0, ${locationElHeight * -currentIndex}px, 0)`;

	}

	const onProjectClick = () => {
		navigate(`/${selectedProjects[currentIndex].uid}`);
	}

	const handleOnKeyDown = e => {
		if (e.key === 'Enter') {
			onProjectClick();
		}
	}

	return (
		<section
			className="SliderProjects"
			data-is-on-screen
		>
			<style>
				{`
				.SliderProjects:after {
					background-color: transparent !important;
				}
				`}
			</style>
			
			<div  className="SliderProjects__MobileSWiper showOnTablet">
				<ImageSliderSwiperjs slides={newSliderData} showControls={true} />
			</div>

			<div
				className="SliderProjects__Container"
				role="button"
				tabIndex={0}
			>
				<ul className="SliderProjects__Images">
					{selectedProjects.map((project, index) => (
						<li
							className="SliderProjects__ImageItem"
							key={`projects-selected-project-image-${index}`}
							
						>
							<Picture
								className="SliderProjects__Image"
								src={project.image.fluid.src}
								srcSet=" "
								alt={project.image.alt}
								sizes="100vw"
							/>							
						</li>
					))}
				</ul>
			</div>
			<div className="SliderProjects__Infos">
				<div className="SliderProjects__InfosLeft" >
					<h5
						className="SliderProjects__InfosTitle"
						style={{ cursor: "default", width: "110%" }}
					>
						<div
							// onClick={onProjectClick}
							// onKeyDown={handleOnKeyDown}
							// role="button"
							tabIndex={0}
						>
							<div className="SliderProjects__InfosTitleRoller">
								{selectedProjects.map((project, index) => (
									<div className="SliderProjects__InfosTitleItem" key={`project-slider-title-${index}`}>
										{project.title.text}
									</div>
								))}
							</div>
						</div>
					</h5>
					<h6 className="SliderProjects__InfosLocation">
						<div className="SliderProjects__InfosLocationRoller">
							{selectedProjects.map((project, index) => (
								<div className="SliderProjects__InfosLocationItem" key={`project-slider-location-${index}`}>
									{project.location.text}
								</div>
							))}
						</div>
					</h6>
				</div>
				<div className="SliderProjects__Arrows">
					<svg className="SliderProjects__Arrow SliderProjects__Arrow--Left" onClick={() => { updateCurrentSlide('prev') }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip0)" stroke="#fff" strokeWidth="1.5"><path d="M20 10.17H2.03M10.29 1.16L1.313 10.17l8.977 9.008"/></g><defs><clipPath id="clip0"><path fill="#fff" transform="rotate(180 10 9.923)" d="M0 0h20v19.354H0z"/></clipPath></defs></svg>
					<svg className="SliderProjects__Arrow SliderProjects__Arrow--Right" onClick={() => { updateCurrentSlide('next') }} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><g clipPath="url(#clip1)" stroke="#fff" strokeWidth="1.5"><path d="M0 10.17h17.97M9.71 19.179l8.977-9.008L9.71 1.163"/></g><defs><clipPath id="clip1"><path fill="#fff" transform="translate(0 .493)" d="M0 0h20v19.354H0z"/></clipPath></defs></svg>
				</div>
			</div>
		</section>
	)
}

export default SliderProjectsTCA;